/* eslint-disable */
import axios from "axios";
import dotenv from "dotenv";
import jwt from "jsonwebtoken";
dotenv.config();

var apiIP = "";
if (process.env.VUE_APP_IP_API && process.env.VUE_APP_PORT_REST) {
  apiIP = `${process.env.VUE_APP_IP_API}:${process.env.VUE_APP_PORT_REST}`;
} else {
  apiIP = "https://old-app-api.clubecerto.com.br";
}

const decoded = jwt.decode(localStorage.getItem("user-token"));
if (decoded) var idEmpresa = decoded.id_empresa;

export default {
  getJson(jsonName) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = apiIP + "/g/buscaJson/" + process.env.VUE_APP_SITE + "/" + jsonName;

        axios
          .get(endpoint)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },
  getAll(controllerName, extraParams, action = "list") {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        if (extraParams)
          if (extraParams.PageNumber != undefined) {
            if (action == "") {
              endpoint = endpoint + "Page/" + extraParams.PageNumber;
            } else {
              endpoint = endpoint + "/" + extraParams.PageNumber;
            }
          }
        endpoint = endpoint.replace("idEmpresa=null", idEmpresa).replace("idEmpresa=undefined", idEmpresa);
        console.log("---->", endpoint);
        var token = localStorage.getItem("user-token");
        axios
          .get(endpoint, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  getOne(controllerName, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        const endpoint = `${apiIP}/${controllerName}/${extraParams.PrimaryKey}/${extraParams.PageNumber}`;
        console.log(endpoint);
        axios
          .get(endpoint, {
            extraParams,
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  getFilter(controllerName, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        const endpoint = `${apiIP}/${controllerName}/filter/${extraParams.SearchText}?page=${extraParams.PageNumber}`;

        axios
          .get(endpoint, {
            extraParams,
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  post(controllerName, action = "create", data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        if (extraParams)
          if (extraParams.PageNumber != undefined) {
            if (action == "") {
              endpoint = endpoint + "Page/" + extraParams.PageNumber;
            } else {
              endpoint = endpoint + "/" + extraParams.PageNumber;
            }
          }
        if (idEmpresa) data.idEmpresa = idEmpresa;
        console.log(endpoint, data);
        var token = localStorage.getItem("user-token");
        axios
          .post(endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response.data) {
              if (error.response.data.error) {
                console.log(error);
                if (error.response.data.error.errorMessage) reject(error.response.data.error.errorMessage);
                else reject();
              } else {
                reject(error);
              }
            }
            if (error) {
              reject();
            }
          });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },

  login(controllerName, action = "login", data) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        var basicAuth = "Basic " + btoa(JSON.stringify(data));
        console.log("Authorization", basicAuth);
        axios
          .post(
            endpoint,
            {},
            {
              headers: {
                Authorization: basicAuth,
              },
            }
          )
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response.data) {
              if (error.response.data.error) {
                console.log(error);
                if (error.response.data.error.errorMessage) reject(error.response.data.error.errorMessage);
                else reject();
              } else {
                reject(error);
              }
            }
            if (error) {
              reject();
            }
          });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },

  getEndPoint(controllerName, action, data, extraParams) {
    var endpoint = `${apiIP}/${controllerName}/${action}`;
    return endpoint;
  },

  upload(controllerName, action, data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        //console.log(endpoint)

        axios
          .post(endpoint, data, {
            headers: {
              "Content-Type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
            },
          })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error.response.data.error.errorMessage);
          });
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  },

  put(controllerName, action = "update", data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        data.idEmpresa = idEmpresa;
        var token = localStorage.getItem("user-token");

        axios
          .put(endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error.response.data.error.errorMessage);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  delete(controllerName, action = "update", data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        data = {
          headers: {
            Authorization: token,
          },
        };
        axios
          .delete(endpoint, data)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error.response.data.error.errorMessage);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  validaToken(token, controllerName, action) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        console.log(endpoint);
        axios
          .post(
            endpoint,
            {
              front: true,
            },
            {
              headers: {
                Authorization: token,
              },
            }
          )
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            console.log(error);
            resolve({
              data: {
                result: false,
              },
            });
          });
      } catch (e) {
        reject(e);
      }
    });
  },
};
